<template>
  <el-config-provider
    :locale="lang[appStore.language]"
    :size="appStore.size"
    namespace="el"
  >
    <router-view />
    <!-- <el-button
      type="warning"
      size="large"
      round
      :icon="Service"
      @click="open2"
      style="position: fixed; right: 1%; bottom: 2%; z-index: 55"
      >聯絡我們</el-button
    > -->
  </el-config-provider>
</template>
<style>
.el-notification__title {
  font-size: 1.2rem !important;
}
</style>

<script setup>
//lang and size config
// import zh from 'element-plus/es/locale/lang/zh-cn'
import zhTw from "element-plus/es/locale/lang/zh-tw";
import { Service } from "@element-plus/icons-vue";
import { ElNotification } from "element-plus";
// import en from 'element-plus/es/locale/lang/en'

import { setToken } from "@/utils/auth";

import { useAppStore } from "@/store/app";
const appStore = useAppStore();

let lang = { zhTw };

let notificationInstance = null;
const open2 = () => {
  if (notificationInstance) {
    notificationInstance.close();
  }
  notificationInstance = ElNotification({
    title: "聯絡我們",
    dangerouslyUseHTMLString: true,
    zIndex: 55,
    message:
      '<h5 class="my-5">各縣市公會對接窗口</h5><div class="mb-4"><h6 class="mb-2">台北市機車商業同業公會</h6><p>聯絡人：陳穎輝</p><p>聯絡電話：02-25775128</p><p>(唐秘書或任秘書)</p></div><div class="mb-4"><h6 class="mb-2">嘉義市機車商業同業公會</h6><p>聯絡人：曾斐琴</p><p>聯絡電話：0912-052052</p></div><div class="mb-4"><h6 class="mb-2">高雄市直轄市機車商業同業公會</h6><p>聯絡人：陳星雅</p><p>聯絡電話：07-7193346</p></div><div class="mb-4"><h6 class="mb-2">屏東縣機車商業同業公會</h6><p>聯絡人：陳文麗</p><p>聯絡電話：08-7342269</p></div><div class="mb-4"><h6 class="mb-2">若非上述地區，請洽：</h6><p>ReMo瑞摩智能</p><p>聯絡電話：04-37071383</p><p><a href="https://www.facebook.com/remo.vehicle" class="text-primary">FACEBOOK</a>、<a href="https://lin.ee/zcSMMp3" class="text-primary">LINE</a></p></div>',
    duration: 0,
  });
};

onBeforeMount(() => {
  //set tmp token when setting isNeedLogin false
  if (!appStore.settings.isNeedLogin) setToken(appStore.settings.tmpToken);
});
</script>
