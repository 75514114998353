const TokenKey = 'branch-Token'

export function getToken() {
  return localStorage.getItem(TokenKey)
}

export function setToken(token) {
  return localStorage.setItem(TokenKey, token)
}

export function setHeadquarter(data) {
  return localStorage.setItem('headquarterId', data)
}

export function getHeadquarter() {
  return localStorage.getItem('headquarterId')
}

export function setForWorkOrder(data) {
  return localStorage.setItem('forWorkOrder', data)
}

export function getForWorkOrder() {
  return localStorage.getItem('forWorkOrder')
}

export function setIsManager(data) {
  // 現在登入者，是否是本分店管理員？ "branchManager":true 是
  return localStorage.setItem('isManager', data)
}

export function getIsManager() {
  // 現在登入者，是否是本分店管理員？ "branchManager":true 是
  return localStorage.getItem('isManager')
}
export function removeIsManager() {
  return localStorage.removeItem('isManager')
}
export function setIsWarranty(data) {
  // 現在登入者，是否是本分店管理員？ "branchWarranty":true 是
  return localStorage.setItem('isWarranty', data)
}

export function getIsWarranty() {
  // 現在登入者，是否是本分店管理員？ "branchWarranty":true 是
  return localStorage.getItem('isWarranty')
}
export function removeIsWarranty() {
  return localStorage.removeItem('isWarranty')
}


export function removeToken() {
  return localStorage.removeItem(TokenKey)
}


export function getSearchKeyword() {
  return JSON.parse(localStorage.getItem('searchKeyword'))
}
export function setSearchKeyword(data) {
  return localStorage.setItem('searchKeyword', JSON.stringify(data))
}


export function getSwitchAccount() {
  return JSON.parse(localStorage.getItem('switchAccount'))
}
export function setSwitchAccount(data) {
  console.log(data)
  return localStorage.setItem('switchAccount', JSON.stringify(data))
}
// sa訊息數量
export function getSaMessages() {
  return JSON.parse(localStorage.getItem('SaMessages'))
}
export function setSaMessages(data) {
  return localStorage.setItem('SaMessages', JSON.stringify(data))
}
