import { createRouter, createWebHashHistory, createWebHistory } from 'vue-router'
import Layout from '@/layout'

/* Router Modules */
// XXX: transferRouter為部品調度模組，暫時不使用
// import transferRouter from './modules/transfer.js'
import reserveRouter from './modules/reserve.js'
import chartsRouter from './modules/charts'
import useExample from './modules/useExample'
import codeGenerator from './modules/codeGenerator'
import routerJson from '../api/main-menu.json'

/**
 * 抓取 src/api/main-menu.json 中的路由配置
 * 自動產生 router
 *
 *
 **/
const constantRoutesTemp = routerJson.map((item) => {
  return {
    path: item.path,
    component: Layout,
    manager: item.manager,
    children: item.children.map((child) => {
      return {
        path: child.path,
        component: () => import(`../views/${child.dir}/${child.component_import}.vue`),
        name: child.name,
        hidden: child.hidden,
        meta: {
          title: child.meta.title,
          // icon: child.meta.icon
          // elSvgIcon : child.meta.elSvgIcon
          fontAwesome : child.meta.fontAwesome
        }
      }
    })
  }
})

export const constantRoutes = [
  // {
  //   path: '/redirect',
  //   component: Layout,
  //   hidden: true,
  //   children: [
  //     {
  //       path: '/redirect/:path(.*)',
  //       component: () => import('@/views/redirect')
  //     }
  //   ]
  // },
  {
    path: '/confirm_reserve/:otp',
    name: 'ConfirmReserve',
    component: () => import('@/views/free/confirmReserve.vue'),
    hidden: true
  },
  {
    path: '/login',
    component: () => import('@/views/login/Login.vue'),
    hidden: true
  },
  {
    path: '/404',
    component: () => import('@/views/error-page/404.vue'),
    hidden: true
  },
  {
    path: '/401',
    component: () => import('@/views/error-page/401.vue'),
    hidden: true
  },
  {
    path: '/fake_sa',
    component: () => import('@/views/branch/fake_sa.vue'),
    hidden: true
  },
  {
    path: '/',
    component: Layout,
    redirect: '/dashboard',
    children: [
      {
        path: 'dashboard',
        name: 'Dashboard',
        component: () => import('@/views/dashboard/index.vue'),
        //using el svg icon, the elSvgIcon first when at the same time using elSvgIcon and icon
        meta: { title: '首頁', fontAwesome: 'fa-solid fa-house' }
      }
    ]
  },
  {
    // 維修
    path: '/print',
    component: () => import('@/views/repair/print.vue')
  },
  {
    path: '/print_chart',
    component: () => import('@/views/charts/components/print.vue')
  },
  {
    path: '/print_total',
    component: () => import('@/views/charts/components/print_total.vue')
  },
  // {
  //   path: "/tainan",
  //   component: () => import("@/views/tailored/tainan.vue"),
  // },
  {
    path: '/tainan',
    beforeEnter() {
      location.href = 'https://web2.envimac.com.tw/dsms/RegData'
    }
  },
  // {
  //   path: "/liff_test",
  //   component: () => import("@/views/tailored/liff_test.vue"),
  // },
  {
    path: '/liff_logout',
    component: () => import('@/views/tailored/liff_logout.vue')
  },
  {
    path: '/tainan_demo',
    component: () => import('@/views/tailored/tainan.vue')
  },
  {
    path: '/repair',
    component: Layout,
    hidden: true,
    children: [
      {
        path: 'car_detail',
        name: 'cardetail',
        component: () => import('@/views/repair/carDetail.vue')
        //using el svg icon, the elSvgIcon first when at the same time using elSvgIcon and icon
        // meta: { title: '首頁', elSvgIcon: 'Fold' }
      },
      {
        path: 'edit',
        name: 'RepairEdit',
        component: () => import('@/views/repair/edit.vue')
        //using el svg icon, the elSvgIcon first when at the same time using elSvgIcon and icon
        // meta: { title: '首頁', elSvgIcon: 'Fold' }
      },
      {
        path: 'resume',
        name: 'resume',
        component: () => import('@/views/repair/resume.vue')
        //using el svg icon, the elSvgIcon first when at the same time using elSvgIcon and icon
        // meta: { title: '首頁', elSvgIcon: 'Fold' }
      }
    ]
  },
  // codeGenerator,
  ...constantRoutesTemp,
  // XXX: transferRouter為部品調度模組，暫時不使用
  // transferRouter,
  reserveRouter
]

/**
 * asyncRoutes
 * the routes that need to be dynamically loaded based on user roles
 */
export const asyncRoutes = []

const router = createRouter({
  history: createWebHistory(),
  scrollBehavior: () => ({ top: 0 }),
  routes: constantRoutes
})

export default router
